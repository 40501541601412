@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --text-brand: #15c2c0;
  --text-black: #222;
  --text-gray: #6b7280;
}

@media (prefers-color-scheme: dark) {
  :root {
    --text-black: #fff;
  }
}

.bg-overlay {
  position: relative;
  z-index: 0;
}

.bg-overlay::after {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(47deg, #15c2c0 0%, #020617 100%);
  opacity: 0.94;
  z-index: -2;
}

.locale-switcher {
  /* Remove default arrow */
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  /* Add custom chevron */
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>');
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1.25rem;
}
