.markdown p {
  margin-bottom: 1rem;
  color: var(--text-black);
}

.markdown ul {
  color: var(--text-black);
  list-style: disc;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.markdown strong {
  margin-top: 1.5rem;
  font-size: 1.75rem;
}

.markdown a {
  color: var(--text-brand);
  text-decoration: underline;
}

.markdown em {
  color: var(--text-gray);
}
